import {Module} from 'vuex';

import header from './header/module';

import actions from './actions';
import state, {RootState} from './state';
import mutations from './mutations';

const store: Module<RootState, {}> = {
  state,
  actions,
  mutations,
  modules: {
    header,
  },
  namespaced: true,
};

export default store;
