import { DefineMutations } from 'vuex-type-helper';
import {HeaderState} from './state';
import {PortfolioDropdownItem} from '@/app/models/header/models';
import {QuickSearch} from '@/app/api/graphql/types';


interface HeaderMutations {
  portfoliosLoaded: {
    portfolios: PortfolioDropdownItem[],
  };
  loadingPortfolios: {};
  startSearch: {};
  finishSearch: {
    data: QuickSearch.Search[],
  };
}

const mutations: DefineMutations<HeaderMutations, HeaderState> = {
  portfoliosLoaded(state, { portfolios }) {
    state.portfolioDropdownItems = portfolios;
    state.loadingPortfolios = false;
  },

  loadingPortfolios(state) {
    state.loadingPortfolios = true;
  },

  startSearch(state) {
    state.searching = true;
  },

  finishSearch(state, { data }) {
    state.searching = false;
    state.searchItems = data;
  },
};

export default mutations;

export { HeaderMutations };
