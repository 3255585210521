import {DefineGetters} from 'vuex-type-helper';
import {HeaderState} from './state';
import {FilteredSearchResults} from '@/app/models/header/models';
import {QuickSearch} from '@/app/api/graphql/types';

interface HeaderGetters {
  getPortfolios: {};
  searchItemsGrouped: {};
}

const getters: DefineGetters<HeaderGetters, HeaderState> = {
  getPortfolios(state) {
    return state.portfolioDropdownItems;
  },
  searchItemsGrouped(state): FilteredSearchResults {
    const results: FilteredSearchResults = {};
    state.searchItems.forEach((item: QuickSearch.Search) => {
      if (!results.hasOwnProperty(item.type)) {
        results[item.type] = [];
      }
      results[item.type].push(item);
    });

    return results;
  },
};

export default getters;

export {HeaderGetters};
