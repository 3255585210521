import {DefineMutations} from 'vuex-type-helper';
import {RootState} from './state';

import NavbarUser from '@/app/models/NavbarUser';
import Notification from '@/app/models/notifications/Notification';

interface RootMutations {
  changeLocale: {
    locale: string,
  };
  setUser: {
    user: NavbarUser,
  };
  setImpersonated: {
    impersonated: boolean,
  };
  addNotifications: {
    notifications: Notification[],
  };
  setClientId: {
    id: string,
  };
}

const mutations: DefineMutations<RootMutations, RootState> = {
  changeLocale(state, {locale}) {
    state.locale = locale;
  },
  setUser(state, {user}) {
    state.user = user;
  },
  setImpersonated(state, {impersonated}) {
    state.impersonated = impersonated;
  },
  addNotifications(state, {notifications}) {
    state.notifications.push(...notifications);
  },
  setClientId(state, {id}) {
    state.client.clientId = id;
  },
};

export default mutations;

export {RootMutations};
