








import {Component, Prop, Vue} from 'vue-property-decorator';
import Task from '@/app/models/notifications/Task';

@Component
export default class TaskItem extends Vue {
  @Prop() task!: Task;
}
