














import {Component, Prop, Vue} from 'vue-property-decorator';
import ModalWrapper from './ModalWrapper.vue';

@Component({
  components: {ModalWrapper}
})
export default class DefaultModal extends Vue {
  @Prop() show: boolean;

  close() {
    this.$emit('close');
  }
}
