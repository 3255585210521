






















import {Component, Prop, Vue} from 'vue-property-decorator';
import Dropdown from './dropdown/Dropdown.vue';
import LocaleDropdown from './dropdown/settings/LocaleDropdown.vue';
import {DropdownPosition} from './dropdown/DropdownPosition';

@Component({
  i18nOptions: {keyPrefix: 'navbar'},
  components: {
    Dropdown,
    LocaleDropdown,
  },
})
export default class AnonymousNavbar extends Vue {
  @Prop() currentPage: string;
  dropdownPosition = DropdownPosition;
  localeDropdownOpen: boolean = false;

  get locale() {
    return this.$i18n.i18next.language.toLocaleUpperCase();
  }
}
