import {ExpenseCategory} from '@/app/api/graphql/types';
import {
  Min,
  IsNotEmpty,
  IsDate,
  IsString,
  IsNumber, IsOptional,
} from 'class-validator';
import {omit} from 'lodash';
import i18next from '@/app/i18n';

class ExpenseForm {
  @IsNumber({}, {message: i18next.t('validation:isnumber')})
  @Min(0, {message: i18next.t('validation:lessthanzero')})
  amount: number;

  @IsNumber({}, {message: i18next.t('validation:isnumber')})
  @Min(0, {message: i18next.t('validation:lessthanzero')})
  amountVat: number;

  @IsNotEmpty({message: i18next.t('validation:required')})
  @IsString({message: i18next.t('validation:isstring')})
  description: string;

  @IsNotEmpty({message: i18next.t('validation:required')})
  category: ExpenseCategory | null = null;

  @IsOptional()
  @IsDate({message: i18next.t('validation:isdate')})
  paymentDate: Date;

  @IsDate({message: i18next.t('validation:isdate')})
  invoiceDate: Date;

  toFormData() {
    const fd = new FormData();
    fd.append('expenseData', JSON.stringify(omit(this, 'scannedInvoice')));

    return fd;
  }
}

export default ExpenseForm;
