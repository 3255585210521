















import Controls from './Controls.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  i18nOptions: { keyPrefix: 'navbar.items'},
  components: {
    Controls,
  },
})
export default class AdminNavbar extends Vue {
  @Prop() currentPage: string;
}
