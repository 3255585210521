




















import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseInput from './BaseInput.vue';
import Multiselect from 'vue-multiselect';

export interface GroupedSelectGroup {
  group: string;
  options: GroupedSelectOption[];
}

export interface GroupedSelectOption {
  name: string;
  label: string;
}

@Component({
  components: {
    BaseInput,
    Multiselect,
  }
})
export default class GroupedSelectInput extends Vue {
  @Prop() value: GroupedSelectOption;
  @Prop() id: string;
  @Prop() label: string;
  @Prop() placeholder: string;
  @Prop() translationPrefix: string;
  @Prop() options: GroupedSelectGroup[];
  @Prop({default: false}) hasError: boolean;
  @Prop() errorMessages: string[];
  @Prop({default: false}) required: boolean;

  input(value: any) {
    this.$emit('input', value);
  }
}
