






import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseInput from './BaseInput.vue';

@Component({
  components: {
    BaseInput,
  }
})
export default class FileInput extends Vue {
  @Prop() value: any;
  @Prop() id: string;
  @Prop() label: string;

  input(e: Event) {
    const target = (e.currentTarget as HTMLInputElement);
    if (target.files !== null && target.files.length) {
      this.$emit('input', target.files[0]);
    }
  }
}
