import ExpenseForm from '@/app/forms/ExpenseForm';
import {IsBoolean, IsDate, IsIn, IsNotEmpty, IsOptional, IsString, ValidateIf} from 'class-validator';
import {omit} from 'lodash';
import i18next from '@/app/i18n';

enum ExpenseBearer {owner = 'owner', allTenants = 'allTenants', specificTenant = 'specificTenant'}

export class PropertyExpenseForm extends ExpenseForm {
  @IsNotEmpty({message: i18next.t('validation:required')})
  @IsIn(Object.keys(ExpenseBearer), {message: i18next.t('validation:invalid')})
  bearer: ExpenseBearer | null = null;

  @IsNotEmpty({message: i18next.t('validation:required')})
  paidTo: string;

  @IsOptional()
  @IsString({message: i18next.t('validation:isstring')})
  reference: string;

  scannedInvoice: any = null;

  @ValidateIf((obj) => obj.bearer === ExpenseBearer.allTenants)
  @IsDate({message: i18next.t('validation:isdate')})
  startDate: Date;

  @ValidateIf((obj) => obj.bearer === ExpenseBearer.allTenants)
  @IsDate({message: i18next.t('validation:isdate')})
  endDate: Date;

  @ValidateIf((obj) => obj.bearer === ExpenseBearer.specificTenant)
  @IsNotEmpty({message: i18next.t('validation:required')})
  specificTenant: string | null = null;

  @ValidateIf((obj) => obj.bearer === ExpenseBearer.specificTenant)
  @IsBoolean({message: i18next.t('validation:isboolean')})
  generateInvoice: boolean;

  @ValidateIf((obj) => obj.bearer === ExpenseBearer.specificTenant)
  @IsBoolean({message: i18next.t('validation:isboolean')})
  sendInvoice: boolean;

  toFormData() {
    const fd = new FormData();
    fd.append('scannedInvoice', this.scannedInvoice);
    fd.append('expenseData', JSON.stringify(omit(this, 'scannedInvoice')));

    return fd;
  }
}