import {PortfolioDropdownItem} from '@/app/models/header/models';
import gql from 'graphql-tag';
import graphql from '@/app/api/graphql';


async function loadNavbarPortfolios(clientId: string): Promise<PortfolioDropdownItem[]> {
  const query = gql`
    query($clientId: ID!) {
      portfolios(clientId: $clientId) {
        id
        name
        isActive
      }
    }
  `;

  const data = await graphql.query<any>({
    query,
    variables: {
      clientId,
    },
  });

  return data.data.portfolios as PortfolioDropdownItem[];
}



export {loadNavbarPortfolios};

