import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VueI18Next from '@panter/vue-i18next';
import PrettyCheckbox from 'pretty-checkbox-vue';
import * as vClickOutside from 'v-click-outside-x';

import App from './App.vue';
import store from './store';
import icons from './icons';
import i18next from './i18n';

Vue.use(vClickOutside);
Vue.use(PrettyCheckbox);

library.add(...icons);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.use(VueI18Next);
const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false;

if ((window as any).bus === undefined) {
  (window as any).bus = new Vue();
}

new Vue({
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');

