import graphql from '@/app/api/graphql';
import SearchGQL from '../graphql/search.graphql';
import {QuickSearch, SearchQueryArgs} from '@/app/api/graphql/types';
import {ApolloQueryResult} from 'apollo-client/core/types';

async function querySearch(args: SearchQueryArgs): Promise<ApolloQueryResult<QuickSearch.Query>> {
  return graphql.query<QuickSearch.Query, QuickSearch.Variables>({
    query: SearchGQL,
    variables: {
      queryString: args.query,
    },
  });
}

export {querySearch};
