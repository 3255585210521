





























import Controls from './Controls.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Dropdown from './dropdown/Dropdown.vue';
import PortfolioDropdown from './dropdown/portfolio/PortfolioDropdown.vue';
import {namespace} from 'vuex-class';

const rootState = namespace('root');
@Component({
  i18nOptions: {keyPrefix: 'navbar.items'},
  components: {
    Controls,
    Dropdown,
    PortfolioDropdown,
  },
})
export default class UserNavbar extends Vue {
  @Prop() currentPage: string;
  portfolioDropdownOpen: boolean = false;
  @rootState.State('impersonated') impersonated: boolean;

  // tryModal() {
  //   (window as any).bus.$emit(
  //     'openExpenseModal',
  //     'property',
  //     '719254ae-6f4c-4978-8805-e0eda5df28ee',
  //     '2057abc6-cad2-4722-bbd1-bde9fe5556c8',
  //   );
  // }
}
