/* tslint:disable */

export type Date = any;

export type Money = any;

export interface Query {
    currentClient: Client;
    client: Client | null;
    portfolios: Portfolio[] | null;
    portfolio: Portfolio | null;
    tenants: Tenant[] | null;
    search: FoundEntity[];
}

export interface Client {
    id: string;
    name: string;
}

export interface Portfolio {
    id: string;
    name: string;
    isActive: boolean | null;
    client: Client | null;
    expenseCategories: ExpenseCategory[] | null;
}

export interface ExpenseCategory {
    id: string;
    name: string;
    groupName: string;
    removable: boolean | null;
}

export interface Tenant {
    id: string;
    contactData: ContactData | null;
}

export interface ContactData {
    id: string;
    companyName: string | null;
    firstName: string | null;
    lastName: string | null;
    displayName: string;
    email: string | null;
    phoneNumber: string | null;
    vatNumber: string | null;
    cocNumber: string | null;
    iban: string | null;
    bic: string | null;
    isPrivate: boolean | null;
}

export interface FoundEntity {
    type: FoundEntityType;
    id: string;
    name: string;
}
export interface ClientQueryArgs {
    id: string;
}
export interface PortfoliosQueryArgs {
    clientId: string;
    limit: number | null;
    offset: number | null;
}
export interface PortfolioQueryArgs {
    id: string;
}
export interface TenantsQueryArgs {
    propertyId: string;
    date: Date | null;
}
export interface SearchQueryArgs {
    query: string;
}

export enum FoundEntityType {
    lease_agreement = "lease_agreement",
    property = "property",
    portfolio = "portfolio"
}

export namespace CurrentClientSubscription {
    export type Variables = {};

    export type Query = {
        __typename?: "Query";
        currentClient: CurrentClient;
    };

    export type CurrentClient = {
        __typename?: "Client";
        id: string;
    };
}

export namespace ExpenseCategories {
    export type Variables = {
        portfolioId: string;
        showRemovable: boolean;
    };

    export type Query = {
        __typename?: "Query";
        portfolio: Portfolio | null;
    };

    export type Portfolio = {
        __typename?: "Portfolio";
        expenseCategories: ExpenseCategories[] | null;
    };

    export type ExpenseCategories = {
        __typename?: "ExpenseCategory";
        id: string;
        name: string;
        groupName: string;
        removable: boolean | null;
    };
}

export namespace QuickSearch {
    export type Variables = {
        queryString: string;
    };

    export type Query = {
        __typename?: "Query";
        search: Search[];
    };

    export type Search = {
        __typename?: "FoundEntity";
        type: FoundEntityType;
        id: string;
        name: string;
    };
}

export namespace PropertyTenants {
    export type Variables = {
        propertyId: string;
        date: Date | null;
    };

    export type Query = {
        __typename?: "Query";
        tenants: Tenants[] | null;
    };

    export type Tenants = {
        __typename?: "Tenant";
        id: string;
        contactData: ContactData | null;
    };

    export type ContactData = {
        __typename?: "ContactData";
        displayName: string;
    };
}
