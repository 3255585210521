import {PortfolioDropdownItem} from '@/app/models/header/models';
import {QuickSearch} from '@/app/api/graphql/types';

interface HeaderState {
  portfolioDropdownItems: PortfolioDropdownItem[];
  loadingPortfolios: boolean;
  searching: boolean;
  searchItems: QuickSearch.Search[];
}

const state: HeaderState = {
  portfolioDropdownItems: [],
  loadingPortfolios: false,
  searching: false,
  searchItems: [],
};

export default state;

export { HeaderState };
