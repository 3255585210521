































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Dropdown from '../Dropdown.vue';
import {DropdownPropsMixin} from '../DropdownPropsMixin';
import {DropdownPosition} from '../DropdownPosition';
import LocaleDropdown from './LocaleDropdown.vue';

@Component({
  i18nOptions: { keyPrefix: 'navbar.settings'},
  components: {
    Dropdown,
    LocaleDropdown,
  },
  mixins: [
    DropdownPropsMixin,
  ],
})
export default class SettingsDropdown extends Vue {
  @Prop() adminControls: boolean;
  dropdownPosition = DropdownPosition;
  localeDropdownOpen: boolean = false;
}
