import {buildAxiosFetch} from 'axios-fetch';
import {createHttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import ApolloClient from 'apollo-client/ApolloClient';

import http from './http';
import {InMemoryCache} from 'apollo-cache-inmemory';

const link = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.map(({message, locations, path}) =>
        window.console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }
    if (networkError) {
      window.console.log(`[Network error]: ${networkError}`);
    }
  })
  .concat(createHttpLink(
    {
      uri: '/graphql',
      fetch: buildAxiosFetch(http),
  }));

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
