import {DefineActions} from 'vuex-type-helper';
import {RootState} from './state';
import {RootMutations} from './mutations';
import jsCookie from 'js-cookie';
import NavbarUser from '@/app/models/NavbarUser';
import Notification from '@/app/models/notifications/Notification';
import {loadClientData} from '@/app/api/header/client';

interface RootActions {
  changeLocale: {
    locale: string;
  };
  determineLocale: undefined;
  determineUser: undefined;
  determineImpersonated: undefined;
  getClientData: undefined;
  addNotifications: {
    notifications: Notification[],
  };
}

const actions: DefineActions<RootActions, RootState, RootMutations> = {
  determineLocale(ctx) {
    const locale = jsCookie.get('_locale');
    if (locale !== undefined) {
      ctx.commit('changeLocale', {locale});
    }
  },

  changeLocale(ctx, {locale}) {
    ctx.commit('changeLocale', {locale});
    jsCookie.set('_locale', locale);
  },

  determineUser(ctx) {
    const user = jsCookie.get('_user') as NavbarUser || NavbarUser.None;
    ctx.commit('setUser', {user});
  },

  determineImpersonated(ctx) {
    const impersonated = jsCookie.get('_impersonated') !== undefined;
    ctx.commit('setImpersonated', {impersonated});
  },

  addNotifications(ctx, {notifications}) {
    ctx.commit('addNotifications', {notifications});
  },

  async getClientData(ctx) {
    const clientData = await loadClientData();
    if (clientData !== null) {
      ctx.commit('setClientId', {id: clientData.clientId});
      await ctx.dispatch('root/header/loadPortfolios', undefined, {root: true});
    } else {
      ctx.commit('setUser', {user: NavbarUser.None});
    }
  },
};

export default actions;

export {RootActions};
