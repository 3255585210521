








import {Component, Prop, Vue} from 'vue-property-decorator';
import Notification from '@/app/models/notifications/Notification';

@Component
export default class NotificationItem extends Vue {
  @Prop() notification!: Notification;
}
