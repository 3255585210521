import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

import {
  faCogs,
  faCheckSquare,
  faSearch,
  faCaretDown,
  faUserTie,
  faUsers,
  faGlobe,
  faSignOutAlt,
  faSuitcase,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faTimes,
  faTicketAlt,
  faBell,
} from '@fortawesome/free-solid-svg-icons';

import {
  fasOTools,
  fasOUsers,
  fasOUser,
  fasOGlobe,
  fasOCog,
  fasOGear,
  fasOHelpCircled,
} from '@/common/icons/orange-svg-icons';

const icons: IconDefinition[] = [
  faCogs,
  faCheckSquare,
  faSearch,
  faCaretDown,
  faUserTie,
  faUsers,
  faGlobe,
  faSignOutAlt,
  faSuitcase,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faTimes,
  faTicketAlt,
  faBell,
  fasOTools,
  fasOUsers,
  fasOUser,
  fasOGlobe,
  fasOCog,
  fasOGear,
  fasOHelpCircled,
];

export default icons;
