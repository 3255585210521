






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CheckboxInput extends Vue {
  @Prop() value: boolean;
  @Prop() label: string;

  input(value: any) {
    this.$emit('input', value);
  }
}
