
import {VNode, CreateElement} from 'vue';
import {LogoType} from './LogoType';

import SmallLogo from './SmallLogo.vue';
import BigColoredLogo from './BigColoredLogo.vue';
import BigLogo from './BigLogo.vue';

interface LogoProps {
  type: LogoType;
}

export default {
  functional: true,
  props: {
    type: LogoType,
  },
  render(h: CreateElement, {props}: { props: LogoProps }): VNode {
    let logo;

    switch (props.type) {
      case LogoType.SmallPear:
        logo = SmallLogo;
        break;
      case LogoType.BigWhite:
        logo = BigLogo;
        break;
      case LogoType.BigColored:
        logo = BigColoredLogo;
        break;
    }

    return h(logo);
  },
};
