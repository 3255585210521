import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import root from './store/module';
export default new Vuex.Store({
  modules: {
    root,
  },
});
