import {ClientData} from '@/app/models/header/models';
import {CurrentClientSubscription} from '@/app/api/graphql/types';
import graphql from '@/app/api/graphql';
import ClientGQL from '../graphql/client.graphql';

async function loadClientData(): Promise<ClientData|null> {
  try {
    const data = await graphql.query<CurrentClientSubscription.Query>({
      query: ClientGQL,
    });

    return {
      clientId: data.data.currentClient.id,
    };
  } catch (e) {
    window.console.log(e);
    return null;
  }
}

export {loadClientData};

