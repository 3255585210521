






import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseInput from './BaseInput.vue';
import Datepicker from 'vuejs-datepicker';

@Component({
  components: {
    BaseInput,
    Datepicker,
  }
})
export default class DateInput extends Vue {
  @Prop() value: any;
  @Prop() id: string;
  @Prop() label: string;
  @Prop() options: any[];
  @Prop({default: false}) hasError: boolean;
  @Prop() errorMessages: string[];
  @Prop({default: false}) required: boolean;

  input(value: string) {
    this.$emit('input', value);
  }

  get inputClass() {
    return this.hasError ? 'has-error' : '';
  }
}
