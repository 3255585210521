










import AnonymousNavbar from './AnonymousNavbar.vue';
import UserNavbar from './UserNavbar.vue';
import AdminNavbar from './AdminNavbar.vue';

import Logo from 'common/components/logo/Logo.vue';
import {LogoType} from '@/components/logo/LogoType';
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import NavbarUser from '@/app/models/NavbarUser';
import NotificationAlertContainer from '@/components/notification/NotificationAlertContainer.vue';
import Notification from '@/app/models/notifications/Notification';

const rootState = namespace('root');
@Component({
  components: {
    none: AnonymousNavbar,
    user: UserNavbar,
    admin: AdminNavbar,
    Logo,
    NotificationAlertContainer,
  },
})
export default class Navbar extends Vue {
  @rootState.State('user') user: NavbarUser;
  @rootState.State('notifications') notifications: Notification[];
  logoType: LogoType = LogoType.SmallPear;

  get currentPage(): string {
    const page = window.location.pathname;
    const pages = [
      'portfolio',
      'property',
      'leaseagreements',
      'invoice',
      'user',
      'client',
      'subscription',
      'login',
      'register',
    ];

    for (const pageVariant of pages) {
      if (page.includes(pageVariant)) {
        return pageVariant;
      }
    }

    if (page === '/') {
      return 'dashboard';
    }

    return '';
  }
}
