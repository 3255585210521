







import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseInput from './BaseInput.vue';

@Component({
  components: {
    BaseInput,
  }
})
export default class TextInput extends Vue {
  @Prop() value: string | number;
  @Prop() id: string;
  @Prop() label: string;
  @Prop({default: false}) number: boolean;
  @Prop({default: false}) hasError: boolean;
  @Prop() errorMessages: string[];
  @Prop({default: false}) required: boolean;

  input(e: Event) {
    if (this.number) {
      this.$emit('input', parseFloat((e.currentTarget as HTMLInputElement).value));
    } else {
      this.$emit('input', (e.currentTarget as HTMLInputElement).value);
    }
  }
}
