


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NotificationAlert extends Vue {
  @Prop() message: string;
  @Prop({default: null}) link: string;
  @Prop({default: null}) linkTitle: string;
  @Prop() type: string;
  @Prop() duration: number;

  isAlive: boolean = true;

  closeAlert() {
    this.isAlive = false;
  }

  mounted() {
    setTimeout(() => {
      this.isAlive = false;
    }, this.duration);
  }

  get iconType() {
    if (this.type === 'notice') {
      return 'info-circle';
    } else if (this.type === 'error') {
      return 'times-circle';
    } else if (this.type === 'success') {
      return 'check-circle';
    }
  }


  get colorClass() {
    if (this.type === 'notice') {
      return 'blue';
    } else if (this.type === 'error') {
      return 'red';
    } else if (this.type === 'success') {
      return 'green';
    }
  }
}
