








import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class BaseInput extends Vue {
  @Prop() name: string;
  @Prop() labelText: string;
  @Prop({default: false}) hasError: boolean;
  @Prop() errorMessages: string[];
  @Prop({default: false}) required: boolean;

  get currentError() {
    if (this.errorMessages !== undefined) {
      return Object.values(this.errorMessages)[0];
    }
  }
}
