


















import {Component} from 'vue-property-decorator';
import {VueAutosuggest} from 'vue-autosuggest';
import {mixins} from 'vue-class-component';
import {namespace} from 'vuex-class';
import {debounce, map, reduce} from 'lodash';
import Dropdown from '../Dropdown.vue';
import {DropdownPosition} from '../DropdownPosition';
import {DropdownPropsMixin} from '../DropdownPropsMixin';
import {PerformSearchAction} from '@/app/store/header/actions';
import {QuickSearch} from '@/app/api/graphql/types';
import {FilteredSearchResults} from '@/app/models/header/models';
import {FoundEntityType} from '@/app/api/graphql/types';

const headerState = namespace('root/header');
@Component({
  i18nOptions: { keyPrefix: 'navbar.search'},
  components: {
    Dropdown,
    VueAutosuggest,
  },
})
export default class SearchDropdown extends mixins(DropdownPropsMixin) {
  @headerState.Action performSearch: PerformSearchAction;
  @headerState.State searchItems: QuickSearch.Search[];
  @headerState.Getter searchItemsGrouped: FilteredSearchResults;
  dropdownPosition = DropdownPosition;

  onInputChange = debounce((current: string) => {
    this.performSearch({query: current});
  }, 2000, {leading: false, trailing: true});

  onClickOutside() {
    if (this.isOpen) {
      this.$emit('closed');
    }
  }

  get suggestions() {
    return map(this.searchItemsGrouped, (value, key) => {
      return {name: key, data: value};
    });
  }

  get types() {
    return Object.keys(this.searchItemsGrouped);
  }

  get sectionConfigs() {
    return reduce(
      this.types,
      (acc, type) => ({
        ...acc, [type]: {
          limit: 5,
          onSelected: this.selectHandler,
          label: this.$t(type),
        },
      }),
      {},
    );
  }

  selectHandler(suggestion: any) {
    const {id, type} = suggestion.item;
    goToFound(id, type);
  }
}

function goToFound(id: string, type: string): void {
  switch (type) {
    case FoundEntityType.lease_agreement:
      window.location.href = `/leaseagreements/${id}`;
      break;
    case FoundEntityType.portfolio:
      window.location.href = `/portfolio/${id}`;
      break;
    case FoundEntityType.property:
      window.location.href = `/property/${id}`;
      break;
  }
}
