import {DefineActions} from 'vuex-type-helper';
import {HeaderState} from './state';
import {HeaderMutations} from './mutations';

import {loadNavbarPortfolios} from '@/app/api/header/portfolios';
import {querySearch} from '@/app/api/header/search';

export interface PerformSearchActionArguments {
  query: string;
}

export type PerformSearchAction = (args: PerformSearchActionArguments) => void;

export interface HeaderActions {
  loadPortfolios: {};
  performSearch: PerformSearchActionArguments;
}
const actions: DefineActions<HeaderActions, HeaderState, HeaderMutations> = {
  async loadPortfolios(ctx) {
    ctx.commit('loadingPortfolios', {});
    const portfolios = await loadNavbarPortfolios(ctx.rootState.root.client.clientId);
    ctx.commit('portfoliosLoaded', {portfolios});
  },
  async performSearch(ctx, {query}) {
    ctx.commit('startSearch', {});
    const results = await querySearch({query});
    window.console.log(results);
    ctx.commit('finishSearch', { data: results.data.search });
  },
};

export default actions;
