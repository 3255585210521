







import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Dropdown from '../Dropdown.vue';
import {DropdownPropsMixin} from '../DropdownPropsMixin';
import {DropdownPosition} from '../DropdownPosition';
import NotificationList from '@/components/notification/NotificationList.vue';
import Notification from '@/app/models/notifications/Notification';
import {namespace} from 'vuex-class';

const rootState = namespace('root');
@Component({
  components: {
    Dropdown,
    NotificationList,
  },
})
export default class NotificationDropdown extends mixins(DropdownPropsMixin) {
  @rootState.State('notifications') notifications: Notification[];
  dropdownPosition = DropdownPosition;
  onClickOutside() {
    if (this.isOpen) {
      this.$emit('closed');
    }
  }
}
