import actions from './actions';
import state, {HeaderState} from './state';
import mutations from './mutations';
import getters from './getters';
import {Module} from 'vuex';

const store: Module<HeaderState, {}> = {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};

export default store;
