











import {Component, Prop, Vue} from 'vue-property-decorator';
import Task from '@/app/models/notifications/Task';
import Notification from '@/app/models/notifications/Notification';
import TotalTaskItem from './TotalTaskItem.vue';
import TaskItem from './TaskItem.vue';
import NotificationItem from './NotificationItem.vue';
import ZeroNotificationsItem from './ZeroNotificationsItem.vue';

@Component({
  components: {
    NotificationItem,
    TaskItem,
    TotalTaskItem,
    ZeroNotificationsItem,
  },
})
export default class NotificationList extends Vue {
  @Prop({
    default: () => [] as Task[],
  })
  tasks: Task[];

  @Prop({
    default: () => [] as Notification[],
  })
  notifications: Notification[];
}
