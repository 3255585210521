




































































































































































import {map, reduce} from 'lodash';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DefaultModal from './DefaultModal.vue';
import SecondaryButton from '@/components/common/button/SecondaryButton.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import FileInput from '@/components/common/input/FileInput.vue';
import SimpleSelectInput from '@/components/common/input/SimpleSelectInput.vue';
import SelectInput from '@/components/common/input/SelectInput.vue';
import GroupedSelectInput from '@/components/common/input/GroupedSelectInput.vue';
import {GroupedSelectGroup} from '@/components/common/input/GroupedSelectInput.vue';
import CheckboxInput from '@/components/common/input/CheckboxInput.vue';
import DateInput from '@/components/common/input/DateInput.vue';
import {loadExpenseCategories, savePortfolioExpense, savePropertyExpense} from '@/app/api/modals/expense';
import {ExpenseCategory} from '@/app/api/graphql/types';
import {validate} from 'class-validator';
import {PropertyExpenseForm} from '../../app/forms/PropertyExpenseForm';
import {PortfolioExpenseForm} from '../../app/forms/PortfolioExpenseForm';
import {loadAvailableTenants} from '../../app/api/modals/expense';
import {Tenant} from '../../app/api/graphql/types';

const keyPrefix = 'modal.expenses';

@Component({
  i18nOptions: {keyPrefix},
  components: {
    DefaultModal,
    SecondaryButton,
    TextInput,
    FileInput,
    SimpleSelectInput,
    SelectInput,
    GroupedSelectInput,
    CheckboxInput,
    DateInput,
  },
})
export default class ExpenseModal extends Vue {
  @Prop() show: boolean;
  @Prop() modalType: string;
  @Prop() propertyId: string;
  @Prop() portfolioId: string;

  tenantOptions: Tenant[] = [];

  categoryOptions: GroupedSelectGroup[] = [];
  bearerOptions: string[] = ['owner', 'allTenants', 'specificTenant'];

  form: PortfolioExpenseForm | PropertyExpenseForm | null = null;

  formErrors: { [key: string]: any } = {};

  async created() {
    if (this.modalType === 'portfolio') {
      this.form = new PortfolioExpenseForm();
    } else {
      this.form = new PropertyExpenseForm();
    }
    const data = await loadExpenseCategories(this.portfolioId, false);

    this.categoryOptions = Object.values(reduce(
      data,
      (rest: { [key: string]: GroupedSelectGroup }, item: ExpenseCategory | any) => {
        if (!rest.hasOwnProperty(item.groupName)) {
          rest[item.groupName] = {
            group: this.$t(item.groupName),
            options: [],
          };
        }
        const label = this.$t(item.name);
        const newItem = {
          name: item.name,
          label: label === `${keyPrefix}.${item.name}` ? item.name : label,
          id: item.id
        };
        rest[item.groupName].options.push(newItem);
        return rest;
      }, {} as { [key: string]: GroupedSelectGroup }));
  }

  close() {
    this.$emit('close');
  }

  async save() {
    if (await this.validateForm() === true) {
      let result = false;
      if (this.modalType === 'portfolio') {
        result = await savePortfolioExpense(this.form as PortfolioExpenseForm, this.portfolioId);
      } else {
        result = await savePropertyExpense(this.form as PropertyExpenseForm, this.portfolioId, this.propertyId);
      }
      if (result) {
        window.location.reload();
      }
    }
  }

  async validateForm(): Promise<boolean> {
    if (this.form !== null) {
      const errors = await validate(this.form);
      console.log(errors);
      if (errors.length) {
        this.formErrors = {};
        for (const error of errors) {
          this.formErrors[error.property] = error;
        }

        return false;
      }

      return true;
    }
    return false;
  }

  @Watch('form.invoiceDate')
  async onInvoiceDateChanged(newVal: Date, oldVal: Date) {
    if (this.form instanceof PropertyExpenseForm && this.form.bearer === 'specificTenant' && newVal !== oldVal) {
      this.tenantOptions = await loadAvailableTenants(this.form.invoiceDate, this.portfolioId, this.propertyId);
      this.form.specificTenant = null;
    }
  }

  @Watch('form.bearer')
  async onBearerChanged(newVal: any, oldVal: any) {
    if (this.form instanceof PropertyExpenseForm && this.form.bearer === 'specificTenant' && this.form.invoiceDate !== undefined && newVal !== oldVal) {
      this.tenantOptions = await loadAvailableTenants(this.form.invoiceDate, this.portfolioId, this.propertyId);
    }
  }

  async onFormChanged(newVal: PortfolioExpenseForm | PropertyExpenseForm, oldVal: PortfolioExpenseForm | PropertyExpenseForm) {
    if (newVal !== null && oldVal !== null) {
      const errors = await validate(newVal);

      this.formErrors = {};
      for (const error of errors) {
        this.formErrors[error.property] = error;
      }

      window.console.log(this.formErrors);
    }
  }


}
