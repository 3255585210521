import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export class DropdownPropsMixin extends Vue {
    @Prop() isOpen!: boolean;

    dropdownOpen = false;

    @Watch('isOpen')
    onOpenChanged(val: boolean) {
      this.dropdownOpen = val;
    }
}
