import i18next from 'i18next';
import jsCookie from 'js-cookie';

import en_validation from './lang/en_validation.json';
import en from './lang/en.json';
import nl_validation from './lang/nl_validation.json';
import nl from './lang/nl.json';


let locale = jsCookie.get('_locale');
if (locale === undefined) {
  locale = 'en';
}

i18next.init({
  lng: locale,
  ns: ['translation', 'validation'],
  resources: {
    en: {translation: en, validation: en_validation},
    nl: {translation: nl, validation: nl_validation},
  },
});

export default i18next;

