import axios from 'axios';

let baseUrl = '/api';

if (process.env.NODE_ENV === 'development') {
  baseUrl = `http://localhost:8000${baseUrl}`;
  // cookie = 'token=8c5c7f860455994163c67542f3934d2d;'
}

const http = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  // headers: {
  //   // Cookie: cookie,
  // },
});

export default http;
