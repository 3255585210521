













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ModalWrapper extends Vue {
  @Prop() show: boolean;

  close() {
    this.$emit('close');
  }

  mounted() {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (this.show && e.keyCode === 27) {
        this.close();
      }
    });
  }
}
