








import {Component, Prop, Vue} from 'vue-property-decorator';
import NotificationAlert from './NotificationAlert.vue';
import Notification from '@/app/models/notifications/Notification';

@Component({
  components: {
    NotificationAlert,
  },
})
export default class NotificationAlertContainer extends Vue {
  @Prop() messages: Notification[];

  // addMessage() {
  //   this.messages.push({id: '3', type: 'error', content: 'error', link: 'http://google.com'})
  // }
}
