







import {Component, Vue} from 'vue-property-decorator';

import Navbar from '@/components/navigation/Navbar.vue';
import {namespace} from 'vuex-class';
import ExpenseModal from '@/components/modal/ExpenseModal.vue';

const rootState = namespace('root');
@Component({
  components: {
    ExpenseModal,
    Navbar,
  },
})
export default class App extends Vue {
  @rootState.Action('determineUser') determineUser: () => void;
  @rootState.Action('determineImpersonated') determineImpersonated: () => void;
  @rootState.Action('determineLocale') determineLocale: () => void;
  @rootState.Action('getClientData') getClientData: () => void;
  @rootState.Action('addNotifications') addNotifications: any;
  @rootState.State('user') user: string;

  openModal: string = '';
  expenseModalType: string = '';
  expenseModalPropertyId: string = '';
  expenseModalPortfolioId: string = '';

  closeModal() {
    this.openModal = '';
  }

  created() {
    this.determineLocale();
    this.determineUser();
    this.determineImpersonated();
    if (this.user === 'user') {
      this.getClientData();
    }
    if ((window as any).bus !== undefined) {
      (window as any).bus.$on('notify', (args: any) => {
        this.addNotifications({notifications: args});
      });
      (window as any).bus.$on('openModal', (modalName: string) => {
        this.openModal = modalName;
      });
      (window as any).bus.$on('openExpenseModal', (type: string, portfolioId: string, propertyId: string) => {
        this.openModal = 'expense';
        this.expenseModalType = type;
        this.expenseModalPortfolioId = portfolioId;
        this.expenseModalPropertyId = propertyId;
      });
    }
  }
}
