
















import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseInput from './BaseInput.vue';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    BaseInput,
    Multiselect,
  }
})
export default class SelectInput extends Vue {
  @Prop() value: any;
  @Prop() id: string;
  @Prop() label: string;
  @Prop() placeholder: string;
  @Prop({default: null}) selectLabel: string;
  @Prop({default: null}) selectTrack: string;
  @Prop() options: any[];
  @Prop({default: false}) hasError: boolean;
  @Prop() errorMessages: string[];
  @Prop({default: false}) required: boolean;

  input(value: any) {
    this.$emit('input', value);
  }
}
