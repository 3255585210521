import {ExpenseCategories, ExpenseCategory, PropertyTenants} from '@/app/api/graphql/types';
import graphql from '@/app/api/graphql';
import http from '@/app/api/http';
import ExpenseCategoriesGQL from '../graphql/expense/categories.graphql';
import PropertyTenantsGQL from '../graphql/tenants.graphql';
import ExpenseForm from '@/app/forms/ExpenseForm';
import {PropertyExpenseForm} from '@/app/forms/PropertyExpenseForm';
import {PortfolioExpenseForm} from '@/app/forms/PortfolioExpenseForm';
import {map} from 'lodash';

async function loadExpenseCategories(portfolioId: string, showRemovable: boolean): Promise<ExpenseCategory[] | null> {
  try {
    const data = await graphql.query<ExpenseCategories.Query, ExpenseCategories.Variables>({
      query: ExpenseCategoriesGQL,
      variables: {
        portfolioId,
        showRemovable,
      },
    });

    if (data.data.portfolio !== null) {
      return data.data.portfolio.expenseCategories;
    }
    return null;

  } catch (e) {
    window.console.log(e);
    return null;
  }
}

async function loadAvailableTenants(invoiceDate: Date, portfolioId: string, propertyId: string): Promise<any> {
  try {
    const resp = await graphql.query<PropertyTenants.Query, PropertyTenants.Variables>({
      query: PropertyTenantsGQL,
      variables: {
        propertyId,
        date: invoiceDate,
      },
    });

    if (resp.data!.tenants!.length) {
      return map(resp.data.tenants, (item: any) => {
        return {
          id: item.id,
          name: item.contactData.displayName,
        };
      });
    }
    return [];
  } catch (e) {
    window.console.log(e);
    return [];
  }
}

async function savePortfolioExpense(form: PortfolioExpenseForm, portfolioId: string): Promise<any> {
  const formData = form.toFormData();
  try {
    const resp = await http.post(`/expense/add/${portfolioId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return true;
  } catch (e) {
    window.console.log(e);
    return false;
  }
}

async function savePropertyExpense(form: PropertyExpenseForm, portfolioId: string, propertyId: string): Promise<any> {
  const formData = form.toFormData();
  try {
    const resp = await http.post(`/expense/add/${portfolioId}/${propertyId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return true;
  } catch (e) {
    window.console.log(e);
    return false;
  }
}

export {loadExpenseCategories, loadAvailableTenants, savePortfolioExpense, savePropertyExpense};
