














import {Component, Vue, Watch} from 'vue-property-decorator';
import SecondaryButton from '@/components/common/button/SecondaryButton.vue';
import Dropdown from '../Dropdown.vue';
import {DropdownPropsMixin} from '../DropdownPropsMixin';
import {DropdownPosition} from '../DropdownPosition';
import {namespace} from 'vuex-class';
import {PortfolioDropdownItem} from '@/app/models/header/models';



const headerState = namespace('root/header');
@Component({
  components: {
    SecondaryButton,
    Dropdown,
  },
  mixins: [
    DropdownPropsMixin,
  ],
})
export default class PortfolioDropdown extends Vue {
  dropdownPosition = DropdownPosition;

  @headerState.Getter('getPortfolios') portfolios: any;

  @Watch('portfolios')
  watchPortfolios() {
    this.$forceUpdate();
  }

  itemActive(portfolio: PortfolioDropdownItem) {
    return window.location.pathname.includes(portfolio.id);
  }

  addPortfolio() {
    window.location.href = '/portfolio/create';
  }
}
