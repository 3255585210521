










import {Component, Prop, Vue} from 'vue-property-decorator';
import {DropdownPosition} from './DropdownPosition';
import {DropdownPropsMixin} from './DropdownPropsMixin';

@Component({
  mixins: [
    DropdownPropsMixin,
  ],
})
export default class Dropdown extends Vue {
  @Prop() position!: DropdownPosition;
}
