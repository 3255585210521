































import {Component, Prop, Vue} from 'vue-property-decorator';
import NotificationDropdown from './dropdown/notifications/NotificationDropdown.vue';
import SearchDropdown from './dropdown/search/SearchDropdown.vue';
import SettingsDropdown from './dropdown/settings/SettingsDropdown.vue';
import {namespace} from 'vuex-class';

const rootState = namespace('root');
@Component({
  components: {
    NotificationDropdown,
    SearchDropdown,
    SettingsDropdown,
  },
})
export default class Controls extends Vue {
  @Prop() adminControls: boolean;
  @rootState.State('locale') locale: string;

  notificationDropdownOpen: boolean = false;
  searchDropdownOpen: boolean = false;
  settingsDropdownOpen: boolean = false;

  closeDropdowns() {
    this.notificationDropdownOpen = false;
    this.searchDropdownOpen = false;
  }

  openHelp() {
    (window as any).triggerGuide(this.locale);
  }
}
