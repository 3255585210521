import Notification from '@/app/models/notifications/Notification';
import {ClientData} from '@/app/models/header/models';

interface RootState {
  locale: string;
  user: string;
  impersonated: boolean;
  notifications: Notification[];
  client: ClientData;
}

const state: RootState = {
  locale: 'en',
  user: 'none',
  impersonated: false,
  notifications: [],
  client: {
    clientId: '',
  },
};

export default state;

export { RootState };
