
















import {Component, Prop, Vue} from 'vue-property-decorator';
import Dropdown from '../Dropdown.vue';
import {DropdownPosition} from '../DropdownPosition';
import {DropdownPropsMixin} from '../DropdownPropsMixin';
import {namespace} from 'vuex-class';

const rootState = namespace('root');
@Component({
  i18nOptions: {keyPrefix: 'navbar.locale'},
  components: {
    Dropdown,
  },
  mixins: [
    DropdownPropsMixin,
  ],
})
export default class LocaleDropdown extends Vue {
  @Prop({default: DropdownPosition.LeftInPlace}) dropdownPosition: DropdownPosition;
  @rootState.Action('changeLocale') changeLocale: any;

  changeLanguage(locale: string) {
    this.$i18n.i18next.changeLanguage(locale);
    this.changeLocale({locale});
    window.location.href = `/language/${locale}`;
  }
}
